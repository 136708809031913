// figma
@import './../../styles/figma/tokens-colors.scss';

// Цвета
$color-black: #000000;
$color-onyx: #111111;
$color-dark-jungle: #222222;
$color-mine-shaft: #333333;
$color-tundora: #444444;
$color-davys-grey: #555555;
$color-ironside-gray: #666666;
$color-tapa-gray: #777777;
$color-taupe-gray: #888888;
$color-mountain-mist: #999999;
$color-dark-gray: #aaaaaa;
$color-gray: #bbbbbb;
$color-celeste: #cccccc;
$color-light-gray: #dddddd;
$color-eallery: #eeeeee;
$color-white: #ffffff;
$color-navy-blue: #0079c1;
$color-prussian-blue: #00426a;
$color-error: #e24831;
$color-carrot-orange: #f39318;

.color-navy-blue {
	color: $color-navy-blue;
}

$color-1: #87ceeb;

$my-sin: #F2B33F;
$summer-sky: #27BDEE;
$black-pearl: #212528;
$slate-grey: #727D87;
$dark-cerulean: #004586;
$eclipse: #3F3F3F;
$white: #ffffff;
$pacific-blue: #009FE3;
$alice-blue: #E8F1FA;
$malibu: #58B5E6;
$cornflower: #9ACCEE;
$blue-whale: #183642;
$light-blue: #A7DBEC;
$mantis: #8EC969;
$ship-grey: #575756;
$bright-grey: #57585B;
$white-smoke: #F3F3F3;
$polo-blue: #99B5CF;
$pattens-blue: #DFE6E8;
$dark-cerulean: #004586;
$danube: #6192BF;
$periwinkle: #C1D5E8;
$pattens-blue: #D9E6F1;
$blizzard-blue: #A9E5F8;
$regal-blue: #133A60;
$color-solitude: #E1E8EE;
$color-air-force-blue: #648DB2;
$solitude: #DEECF9;
