.ic-color--1 {color:#42d9ff;}
.ic-color--2 {color:#ffffff;}
.ic-color--3 {color:#000000;}
.ic-color--4 {color:#0063ff;}
.ic-color--5 {color:#00ffff;}
.ic-color--6 {color:#2cc900;}
.ic-color--7 {color:#42d9f2;}
.ic-color--8 {color:#f5b529;}
.ic-color--9 {color:#790938;}
.ic-color--10 {color:#ed5924;}
.ic-color--11 {color:#c18619;}
.ic-color--12 {color:#3a831a;}
.ic-color--13 {color:#54e81f;}
